@tailwind base;
@tailwind components;
@tailwind utilities;


.modal-contenido {
  padding: 10px 20px;
  margin: 20% auto;
  position: relative;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 1s;
}

#changelog:target {
  opacity: 1;
  pointer-events: auto;
}

#item-description a {
  color: #064E3B;
  font-weight: 700;
  text-decoration: underline;
}
#item-description a:hover  {
  text-decoration: underline;
}

#item-description a:visited {
  color: #4C1D95;
}
#item-description code {
  font-weight: 900;
  line-height: 1.25rem;
  color: #064E3B;
  background-color: #ecfdf5;
}

td a {
  color: #064E3B;
  font-weight: 700;
  text-decoration: underline;
}

td a:visited {
  color: #4C1D95;
}

td a:hover {
  text-decoration: underline;
}

td code {
  font-weight: 900;
  line-height: 1.25rem;
  color: #064E3B;
  background-color: #a7f3d0;

}

li::before {
  content: "\2022";
  color: #064E3B;
  display: inline-block;
  margin-left: 1em;
  margin-right: 0.5em;
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: #059669;
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}